
























import PictureItem from "~/components/Palmers/BaseComponents/Picture/PictureItem.vue";
import {
  defineComponent, useContext,
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: "ProductLabels",
  components: {
    PictureItem
  },
  props: {
    labels: {
      type: Array,
      require: true
    },
    discount: {
      type: [String, Number],
      default: ''
    },
    productImageWidth: {
      type: Number,
      default: 0
    },
    inList: {
      type: Boolean,
      require: true,
      default() {
        return true
      }
    },
    source: {
      type: String,
      default() {
        return 'default'
      }
    },
  },
  setup(props) {
    const {$vsf, $config} = useContext();

    function parseCSS(cssString) {
      const styleObject = {};
      cssString.split(';').forEach(propertyString => {
        const [property, value] = propertyString.split(':');
        if (property && value) {
          const jsProperty = property.trim().replace(/-([a-z])/g, g => g[1].toUpperCase());
          styleObject[jsProperty] = value.trim();
        }
      });
      return styleObject;
    }

    function applyPositionStyles(styles, position) {
      switch (position) {
        case 'top-right':
          styles.right = '0';
          styles.top = '0';
          break;
        case 'top-left':
          styles.left = '0';
          styles.top = '0';
          break;
        case 'top-center': {
          if (styles.width) {
            styles.left = `calc(50% - (${styles.width}) / 2)`
            styles.top = '0';
          }
          break;
        }
        case 'bottom-center': {
          if (styles.width) {
            styles.left = `calc(50% - (${styles.width}) / 2)`
            styles.bottom = '0';
          }
          break;
        }
        case 'bottom-left': {
          if (styles.width) {
            styles.left = '0'
            styles.bottom = '0';
          }
          break;
        }
        case 'bottom-right': {
          if (styles.width) {
            styles.right = '0'
            styles.bottom = '0';
          }
          break;
        }
        case 'middle-center': {
          if (styles.width) {
            styles.left = `calc(50% - (${styles.width}) / 2)`
            styles.top = `50%`
            styles.transform = `translate(0, -50%)`
          }
          break;
        }
        case 'middle-left': {
          if (styles.width) {
            styles.left = '0'
            styles.top = `50%`
            styles.transform = `translate(0, -50%)`
          }
          break;
        }
        case 'middle-right': {
          if (styles.width) {
            styles.right = '0'
            styles.top = `50%`
            styles.transform = `translate(0, -50%)`
          }
          break;
        }
        // Add more cases as needed
      }
    }

    const getLabelStyles = (label) => {
      const styles = {};
      const cssStyles = parseCSS(label.style);
      Object.assign(styles, cssStyles);

      //@ts-ignore
      if (label.size && props.productImageWidth) {
        //@ts-ignore
        const labelWidth = props.productImageWidth * (label.size / 100);
        //@ts-ignore
        styles.width = `${labelWidth}px`;
      }

      if (label.position) {
        applyPositionStyles(styles, label.position);
      }
      return styles;
    };
    return {
      getLabelStyles,
      magentoBaseUrl: $vsf.$magento.config.magentoBaseUrl,
      magentoMediaUrl: $config.magentoMediaUrl
    }
  },
  methods: {
    getSource() {
      if (this.source === 'default') {
        return this.magentoBaseUrl;
      } else {
        return this.magentoMediaUrl;
      }
    },

    getLabel(label) {
      if (this.discount) {
        return label.txt.replace('{}', this.discount);
      }
    },
    getProductLabelStyles(label) {
      const styles = {};

      if (label.position.indexOf('bottom') > -1) {
        styles['bottom'] = '0';
        styles['top'] = 'unset';
      }
      if (label.position.indexOf('middle') > -1) {
        styles['bottom'] = '0';
        styles['top'] = `50%`
        styles['transform'] = `translate(0, -50%)`
      }
      if (label.position.indexOf('top') > -1) {
        styles['bottom'] = 'unset';
        styles['top'] = '0';
      }

      return styles;
    }
  }
});
