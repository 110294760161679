





export default {
  name: "ProductDueDate",
  props: {
    dueDate: {
      type: [Object, Boolean],
      require: true
    }
  }
};
