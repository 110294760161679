import { useContext, ref, watch } from "@nuxtjs/composition-api";

const useStoreWatcher = () => {
  const {$vsf, $config} = useContext();
  const store = ref($vsf.$magento.config.state.getStore());

  const isGiftStore = ref(
    store.value === $config.giftDeStoreView || store.value === $config.giftEnStoreView
  );

  watch(
    () => $vsf.$magento.config.state.getStore(),
    (newVal, oldVal) => {
      store.value = newVal;
      isGiftStore.value = newVal === $config.giftDeStoreView || newVal === $config.giftEnStoreView;
    }
  );

  const getColorAttribute = () => {
    return (isGiftStore.value) ? 'muenze_boxfarbe' : 'color';
  }

  const getSizeAttribute = () => {
    return (isGiftStore.value) ? 'muenze_schleife' : 'pa_size';
  }

  return {
    isGiftStore,
    getColorAttribute,
    getSizeAttribute
  }
};

export default useStoreWatcher;
