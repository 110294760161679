













import {PropType, ref} from "@nuxtjs/composition-api";
import type {
  ConfigurableProductOption
} from '~/modules/GraphQL/types';

import productGetters from "~/modules/catalog/product/getters/productGetters";
import {useUrl} from "~/composables/Palmers";

export default {
  name: "ColorPicker",
  props: {
    option: {
      type: Object as PropType<ConfigurableProductOption>
    },
    activeUid: {
      type: String
    }
  },
  setup() {
    const {getSwatchUrl} = useUrl();
    return {
      productGetters,
      getSwatchUrl
    }
  },
  methods: {
    getSwatchValue(swatchValue) {
      if(swatchValue?.indexOf('/') > -1) {
        //@ts-ignore
        return `url('${this.getSwatchUrl(swatchValue)}')`
      }
      return swatchValue;
    }
  }
};
