<template>
  <div class="product-configurator" v-if="productConfiguration && configurableOptions"
       :class="{'is-gift-store': isGiftStore}">
    <div class="label" v-if="showColorLabel && getActiveColor()">
      <span>{{ isGiftStore ? $t('Box color') : $t('Color') }}:</span>
      <span>{{ getActiveColorOptionLabel() }}</span>
    </div>
    <div class="product-option" v-for="option in configurableOptions" :key="option.attribute_code">
      <div v-if="option.attribute_code === getColorAttribute()">
        <ColorPicker
          :option="option"
          @selectVariation="updateProductConfiguration(option.attribute_uid, $event)"
          :active-uid="productConfiguration[option.attribute_uid]"
          :key="hotUpdate+productConfiguration[option.attribute_uid]"
        />
      </div>
      <div
        v-else-if="option.attribute_code === getSizeAttribute()"
      >
        <div class="label" v-if="isGiftStore">
          <span>{{ $t('Banderole') }}:</span>
        </div>
        <div class="label" v-else-if="showSizeLabel">
          <span>{{ $t('Size') }}:</span>
        </div>
        <Select
          :label-key="'label'"
          :value-key="'uid'"
          :options="option.values"
          :attribute="option"
          :is-exist="isExist"
          :key="hotUpdate+productConfiguration[option.attribute_uid]"
          :class="{'select-v2': select2}"
          :selected="productConfiguration[option.attribute_uid]"
          @selectAction="updateProductConfiguration(option.attribute_uid, $event.uid)"
          :translate-labels="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import useStoreWatcher from "~/composables/Palmers/useStoreWatcher";
import palmersProductGetters from "~/modules/palmers/product/getters/palmersProductGetters";
import {
  ref
} from '@nuxtjs/composition-api';

export default {
  name: 'ProductConfigurator',
  components: {
    ColorPicker: () => import("~/components/Palmers/Product/Swatches/ColorPicker/ColorPicker.vue"),
    Select: () => import("~/components/Palmers/BaseComponents/Select/Select.vue")
  },
  props: {
    product: {
      type: Object,
      require: true
    },
    productConfiguration: {
      type: [Object, Array]
    },
    configurableOptions: {
      type: [Object, Array]
    },
    showColorLabel: {
      type: Boolean,
      default() {
        return false;
      }
    },
    showSizeLabel: {
      type: Boolean,
      default() {
        return true;
      }
    },
    select2: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  watch: {
    product() {
      this.hotUpdate++;
    },
    productConfiguration() {
      this.hotUpdate++;
    }
  },
  setup() {
    const hotUpdate = ref(0);
    const {getColorAttribute, getSizeAttribute} = useStoreWatcher();
    const {isGiftStore} = useStoreWatcher();

    return {
      getColorAttribute,
      getSizeAttribute,
      isGiftStore,
      hotUpdate
    }
  },
  methods: {
    updateProductConfiguration(attributeUid, optionUid) {
      this.$emit('updateProductConfiguration', {
        attributeUid: attributeUid,
        optionUid: optionUid
      });
    },
    getActiveColor() {
      return this.configurableOptions.find((attribute) => attribute.attribute_code === this.getColorAttribute());
    },
    getActiveColorOptionLabel() {
      const color = this.getActiveColor();

      const optionUid = this.productConfiguration[color.attribute_uid];
      return color.values.find((option) => option.uid === optionUid)?.label || '';
    },
    isExist(attribute, optionUid) {
      return palmersProductGetters.isExistingAttributeOption(this.product, attribute, optionUid, this.productConfiguration);
    }
  }
};
</script>
<style lang="scss">
  @import "assets/css/source/components/product-configurator";
</style>
