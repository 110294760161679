










import {PropType} from "@nuxtjs/composition-api";
import type {
  Price
} from '~/modules/GraphQL/types';
export default {
  name: "ProductPrice",
  props: {
    price: {
      type: Object as PropType<Price>
    }
  }
};
