<template>
  <div class="add-to-wishlist" :class="{'is-wishlist': isWishlist}">
    <a
      class="action towishlist primary-invert"
      :class="{'__active': isProductInWishlist && !isWishlist}"
      @click.prevent="$emit('addItemToWishlist'); $event.target.blur()"
      href="#"
    >
      <span>
        {{ $t('Add To Wishlist') }}
      </span>
    </a>
  </div>
</template>
<script>
export default {
  name: "AddToWishList",
  props: {
    product: {
      type: Object,
      require: true
    },
    isAuthenticated: {
      type: Boolean,
      require: true
    },
    isProductInWishlist: {
      type: Boolean,
      require: true
    },
    isWishlist: {
      type: Boolean,
      default() {
        return false;
      }
    }
  }
};
</script>
