













export default {
  name: "AddToCart",
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    custom: {
      type: Boolean,
      default: null,
    },
    isProductInCart: {
      type: Boolean,
      require: true
    },
    canAddProductToCart: {
      type: Boolean,
      require: true
    },
    isWishlist: {
      type: Boolean,
      default() {
        return false;
      }
    }
  }
};
