














































































import {PropType, ref, useContext, defineComponent, Ref, UnwrapRef, computed, watch} from "@nuxtjs/composition-api";
import productGetters from "~/modules/catalog/product/getters/productGetters";
import ProductPrice from "~/components/Palmers/Product/ProductPrice.vue";
import ColorPicker from "~/components/Palmers/Product/Swatches/ColorPicker/ColorPicker.vue";
import Select from "~/components/Palmers/BaseComponents/Select/Select.vue";
import AddToCart from "~/components/Palmers/Product/Actions/AddToCart.vue";
import AddToWishList from "~/components/Palmers/Product/Actions/AddToWishList.vue";
import ProductConfigurator from "~/components/Palmers/Product/Item/ProductConfigurator.vue";

import PictureItem from "~/components/Palmers/BaseComponents/Picture/PictureItem.vue";
import palmersProductGetters from "~/modules/palmers/product/getters/palmersProductGetters";
import ProductLabels from "~/components/Palmers/Product/ProductLabels.vue";
import ProductDueDate from "~/components/Palmers/Product/ProductDueDate.vue";
import {PalmersProductInterface} from "~/modules/palmers/GraphQl/types";
import {merge} from "lodash-es";
import {Product} from "~/modules/catalog/product/types";
import {useUrl} from "~/composables/Palmers";
import useStoreWatcher from "~/composables/Palmers/useStoreWatcher";
import useWishlist from "~/modules/wishlist/composables/useWishlist";
import {useCart} from "~/composables";

export default defineComponent({
  name: "ProductItem",
  props: {
    product: {
      type: Object as PropType<PalmersProductInterface>,
      require: true
    },
    index: {
      type: Number,
      require: false
    },
    addProductToCart: {
      type: Function,
      require: true
    },
    isProductInCart: {
      type: Boolean,
      require: true
    },
    canAddProductToCart: {
      type: Function,
      require: true
    },
    addOrRemoveItemFromWishlist: {
      type: Function,
      require: true
    },
    isAuthenticated: {
      type: Boolean,
      require: true
    },
    isProductInWishlist: {
      type: Function,
      require: true
    },
    configuration: {
      type: Object,
      default() {
        return {};
      }
    },
    isWishlist: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      externalElementWidth: 0,
    }
  },
  components: {
    ProductConfigurator,
    ProductPrice,
    Select,
    ColorPicker,
    AddToCart,
    AddToWishList,
    PictureItem,
    ProductLabels,
    ProductDueDate
  },
  setup(props) {
    const {isGiftStore} = useStoreWatcher();
    const context = useContext();
    const productSelected = <UnwrapRef<Ref> | PalmersProductInterface>ref(props.product);
    const {getProductUrl} = useUrl();
    const {
      loading: loadingWishlist,
      itemInProgress: itemInProgressWishlist
    } = useWishlist();

    const {
      loading: loadingCart,
      itemInProgress: itemInProgressCart
    } = useCart();

    const itemAddToCartLoading = computed(() => loadingCart.value && itemInProgressCart.value.uid === props.product.uid);
    const itemAddToWishlistLoading = computed(() => loadingWishlist.value && itemInProgressWishlist.value.uid === props.product.uid);

    const productMutate = ref(Object.assign(props.product));

    const productType = productGetters.getTypeId(props.product);

    const magentoMediaUrl = context.$config.catalogProductMediaUrl;

    const productConfiguration = ref(props.configuration);

    const isConfigurable = () => {
      return productType === 'ConfigurableProduct'
    }

    const isSimple = () => {
      return productType === 'SimpleProduct'
    }

    const isBundle = () => {
      return productType === 'BundleProduct'
    }

    const isVirtual = () => {
      return productType === 'VirtualProduct'
    }

    const configurableOptions = ref([]);

    const image = ref(palmersProductGetters.getCoverImage(props.product).url);
    const imageLabel = ref(palmersProductGetters.getCoverImage(props.product).label);
    const hoverImage = ref(palmersProductGetters.getImageHover(props.product));
    const imageShowed = ref(image.value);

    const updateMutateProductData = (data) => {
      productMutate.value = merge({},
        productMutate.value,
        {
          configurable_product_options_selection: {
            variant: data
          }
        }
      );

      const index = palmersProductGetters.getVariantIndexByUid(
        productMutate.value,
        data.uid
      );

      image.value = palmersProductGetters.getCoverImage(productMutate.value, index).url;
      imageLabel.value = palmersProductGetters.getCoverImage(productMutate.value, index).label;
      hoverImage.value = palmersProductGetters.getImageHover(productMutate.value, index);
      imageShowed.value = image.value;
    };

    if (isConfigurable()) {
      if (!Object.keys(productConfiguration.value).length) {
        productConfiguration.value = palmersProductGetters.getPreselectedConfiguration(props.product);
      } else if (!Object.keys(props['configuration']).length) {
        productConfiguration.value = palmersProductGetters.getPreselectedConfigurationByAttributes(props.product, productConfiguration.value);
      }

      configurableOptions.value = palmersProductGetters.filterConfigurableOptionsByExisting(props.product, productConfiguration.value);

      const variant = palmersProductGetters.getVariantByConfiguration(props.product, productConfiguration.value);

      updateMutateProductData(variant);

      productConfiguration.value = palmersProductGetters.getConfigurationByVariant(productMutate.value);
    }

    return {
      isGiftStore,
      magentoMediaUrl,
      productSelected,
      productGetters,
      configurableOptions,
      isConfigurable,
      isSimple,
      isBundle,
      isVirtual,
      getProductUrl,
      image,
      imageLabel,
      hoverImage,
      imageShowed,
      palmersProductGetters,
      productConfiguration,
      customQuery: context.$vsf.$magento.api.customQuery,
      productMutate,
      updateMutateProductData,
      itemAddToCartLoading,
      itemAddToWishlistLoading
    }
  },
  methods: {
    updateProductConfiguration({attributeUid, optionUid}) {
      if (this.productConfiguration[attributeUid] !== optionUid) {
        this.productConfiguration[attributeUid] = optionUid;

        this.configurableOptions = palmersProductGetters.filterConfigurableOptionsByExisting(this.product, this.productConfiguration);

        const variant = palmersProductGetters.getVariantByConfiguration(
          this.product,
          this.productConfiguration
        );

        this.updateMutateProductData(variant as Product || null);

        this.productConfiguration = palmersProductGetters.getConfigurationByVariant(this.productMutate);

        this.$emit('updateConfiguration', this.productConfiguration);
      }
    },
    imageHover(enter) {
      //@ts-ignore
      if (this.$device.isMobile) return;
      if (!this.hoverImage) return;

      if (enter) {
        this.imageShowed = this.hoverImage;
      } else {
        this.imageShowed = this.image;
      }
    },
    getPrice() {
      if (this.productMutate?.configurable_product_options_selection?.variant) {
        return productGetters.getPrice(this.productMutate.configurable_product_options_selection.variant);
      }

      return productGetters.getPrice(this.product);
    },
    handleGalleryLoaded(loaded) {
      if (loaded) {
        setTimeout(() => {
          //@ts-ignore
          this.updateExternalElementWidth();
        }, 100)
      }
    },
    updateExternalElementWidth() {
      //@ts-ignore
      if (this.$device.isDesktopOrTablet) {
        const element = this.$refs.productItem as HTMLElement;
        if (element) {
          this.externalElementWidth = element.clientWidth;
        }
      } else {
        this.externalElementWidth = document.documentElement.clientWidth;
      }
    },
  }
});
